import React, { forwardRef } from 'react';
import { useState } from 'react';
import { DropDown } from '../../atoms/drop-down/drop-down';
import { DropDownV2 } from '../../atoms/drop-down-v2/drop-down-v2';
import { Wrapper, Questions, Title } from './frequentQuestionsStyles';
import { frequentQuestions } from '../../../models/frequent-questions';
import { useTranslation } from 'react-i18next';
import { renderParagraphsJson } from '../../../utils/utils';

export const FrequentQuestions = forwardRef<HTMLDivElement>((props, ref) => {
  const [expanded, setExpanded] = useState<number | null>(null);
  const { t } = useTranslation();

  return (
    <Wrapper ref={ref} id='FAQ'>
      <Title>{t('landing.frequent-questions.title')}</Title>
      <Questions>
        {frequentQuestions.map((q, i) => (
          <DropDown
            key={i}
            id={i}
            title={t(`landing.frequent-questions.${q.title}`)}
            content={renderParagraphsJson(t(`landing.frequent-questions.${q.content}`))}
            expanded={expanded}
            setExpanded={setExpanded}
            category="faq"
          />
        ))}
        <DropDownV2
          id={11}
          title={t(`landing.frequent-questions.faqExtra.title`)}
          expanded={expanded}
          setExpanded={setExpanded}
          category="faq_extra"
        />
      </Questions>
    </Wrapper>
  );
});
