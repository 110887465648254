import React from 'react';
import axios from 'axios';
import { Post } from '../../../models/blog';
import BlogPost from '../../molecules/blog-post/blog-post';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { device } from '../../../utils/use-device-type';

const fetchPopularPosts = async (): Promise<Post[]> => {
  const url =
    'https://blog.oncampusabroad.com/wp-json/wordpress-popular-posts/v1/popular-posts?range=all&limit=3';
  const response = await axios.get<Post[]>(url);
  return response.data;
};

export const PopularBlog = ({ onLoad }: { onLoad: () => void }) => {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const getPosts = async () => {
      const posts = await fetchPopularPosts();
      setPosts(posts);
      onLoad();
    };
    getPosts();
  }, []);
  const navigateToBlog = () => {
    window.open('https://blog.oncampusabroad.com/', '_blank');
  };

  return (
    <BorderContainer>
      <PopularBox>
        <TitleBox>
          <Title>{t('landing.blog.title')}</Title>
        </TitleBox>
        <PostsContainer>
          {posts.map((post, index) => (
            <BlogPost key={post.id} post={post} isEven={index % 2 === 1} />
          ))}
        </PostsContainer>
      </PopularBox>
      <ButtonBox>
        <Button onClick={navigateToBlog} id='popular_visit_button'>{t('landing.blog.button')}</Button>
      </ButtonBox>
    </BorderContainer>
  );
};

const TitleBox = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media ${device.tablet} {
    max-width: 710px;
  }

  @media ${device.laptop} {
    max-width: 1100px;
  }
`;

const Title = styled.h2`
  text-align: start;
  margin: 0;
  font-size: 1.8rem;
  margin-bottom: 47px;
  border-bottom: 7px solid #83a8fb;
  padding-bottom: 16px;
  font-size: 24px;

  @media ${device.laptop} {
    font-size: 28px;
  }
`;

const BorderContainer = styled.div`
  border-bottom: 35px solid #011553;
  display: flex;
  flex-direction: column;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 20px;
`;

const Button = styled.button`
  width: 100%;
  max-width: 433px;
`;

const PopularBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px 40px 20px;

  @media ${device.tablet} {
    align-items: center;
  }

  @media ${device.laptop} {
    align-items: center;
    padding: auto;
    padding: 40px 30px 40px 30px;
  }
`;

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  gap: 20px;
  border-radius: 15px;
  @media ${device.tablet} {
    max-width: 710px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 30px;
    max-width: 1100px;
  }
`;
