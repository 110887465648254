import React from 'react';

const parseStyle = (styleString: string): React.CSSProperties => {
  return styleString.split(';').reduce((styleObj, style) => {
    const [property, value] = style.split(':').map((s) => s.trim());
    if (property && value) {
      styleObj[property as keyof React.CSSProperties] = value;
    }
    return styleObj;
  }, {} as Record<string, string>);
};

const tagRegex = /<(\/?\w+)([^>]*)>(.*?)<\/\1>|<(\/?\w+)([^>]*)\/?>/g;

export const renderTextWithStrongAndParagraphs = (text: string) => {
  const parts = [];
  let lastIndex = 0;

  text.replace(
    tagRegex,
    (match, tagName, attributes, innerText, closingTag, selfClosingTag, index) => {
      if (index > lastIndex) {
        parts.push(text.substring(lastIndex, index));
      }
      if (tagName && !closingTag) {
        const styleMatch = attributes.match(/style="([^"]*?)"/);
        const style = styleMatch ? parseStyle(styleMatch[1]) : {};

        if (innerText) {
          parts.push(
            React.createElement(tagName.replace('/', '') as keyof JSX.IntrinsicElements, {
              key: index,
              style,
              dangerouslySetInnerHTML: {
                __html: innerText,
              },
            })
          );
        } else {
          parts.push(
            React.createElement(tagName.replace('/', '') as keyof JSX.IntrinsicElements, {
              key: index,
              style,
            })
          );
        }
      }
      lastIndex = index + match.length;
      return match;
      selfClosingTag();
    }
  );

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts.map((part, index) => {
    if (typeof part === 'string') {
      return <React.Fragment key={index}>{part}</React.Fragment>;
    }
    return part;
  });
};
