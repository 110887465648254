import log from 'loglevel';


log.setDefaultLevel(log.levels.INFO);


export const setLogLevel = (level: log.LogLevelDesc) => {
  log.setLevel(level);
};

export const logError = (message: string, error: any) => {
  log.error(message, error);
};

export const logInfo = (message: string, data?: any) => {
  log.info(message, data);
};

export const logWarn = (message: string, data?: any) => {
  log.warn(message, data);
};

export const logDebug = (message: string, data?: any) => {
  log.debug(message, data);
};

export const logTrace = (message: string, data?: any) => {
  log.trace(message, data);
};

export default log;