import { SessionManager } from '../utils/session-manager';

export interface SessionInfo {
  userId: string | null;
  sessionDuration: number | null;
  pageVisitsCount: number;
  isMobile: boolean;
  isComputer: boolean;
  newSessionPercentage: number;
  userOrigin: string | null;
  pageLoadTime: number;
  searchCount: number;
  isReturningUser: boolean;
  visitFrequency: number;
  query: string;
  userAgent: string;
  buttonId: string;
  originTab: string;
  destinationTab: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_id: string;
}

export function createSessionInfo(pageLoadTime: number, query: string): SessionInfo {
  return {
    userId: SessionManager.getUserId(),
    sessionDuration: SessionManager.endSession(),
    pageVisitsCount: SessionManager.getPageVisitCount(),
    isMobile: SessionManager.isMobile(),
    isComputer: SessionManager.isComputer(),
    newSessionPercentage: SessionManager.getNewSessionPercentage(),
    userOrigin: SessionManager.getUtmSource(),
    pageLoadTime,
    searchCount: SessionManager.getSearchCount(),
    isReturningUser: SessionManager.isReturningUser(),
    visitFrequency: SessionManager.getVisitFrequency(),
    query,
    userAgent: navigator.userAgent,
    buttonId: SessionManager.getButtonId(),
    originTab: SessionManager.getOriginTab(),
    destinationTab: SessionManager.getDestinationTab(),
    utm_source: SessionManager.getUtmSource(),
    utm_medium: SessionManager.getUtmMedium(),
    utm_campaign: SessionManager.getUtmCampaign(),
    utm_id: SessionManager.getUtmId()
  };
}
