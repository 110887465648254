import { useEffect, useState, useRef, useMemo } from 'react';
import AnalyticsService from '../../analytics/AnalyticsService';
import { postOrderDetails, resetOrderDetails } from '../../redux/api/OrderApi';
import { getUserDetails } from '../../redux/api/SecondFlowApi';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  OrderFlow,
  OrderFlowTabs,
  setOrderDetails
} from '../../redux/orderFlow/orderFlowSlice';
import { SessionManager } from '../../utils/session-manager';
import ExtendedInsurance from './tabs/ExtendedInsurance';
import ExtensionFlow from './tabs/ExtensionFlow';
import ExtraPersonalDetails from './tabs/ExtraPersonalDetails';
import HealthCard from './tabs/HealthCard';
import IdentificationDetails from './tabs/IdentificationDetails';
import LoadingTab from './tabs/LoadingTab';
import OrderSummary from './tabs/OrderSummary';
import Payment from './tabs/Payment';
import PersonalDetails from './tabs/PersonalDetails';
import PersonalDetailsConfirmation from './tabs/PersonalDetailsConfirmation';
import TravelDetails from './tabs/TravelDetails';
import ErrorTab from './tabs/ErrorTab';
import { navigateToNextTab } from '../../utils/navigation-helpers';
import { createSessionInfo } from '../../analytics/payload';

const FormController = () => {
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector(state => state.orderFlow);
  const { currentTab, flow, ...orderDetailsWithoutHandlers } = orderDetails;
  const orderApiCall = useAppSelector(state => state.orderApi.orderDetails);
  const checkIdRepetitionApiCall = useAppSelector(
    state => state.orderApi.checkIdRepetition
  );
  const userApiCall = useAppSelector(state => state.secondFlowApi.userApi);
  const urlParams = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState('');
  const extendedInsurance = urlParams.get('extendedInsurance');
  const token = urlParams.get('token');
  const query = urlParams.toString();
  const userId = SessionManager.getUserId();
  const orderDetailsWithUserSession = {
    ...orderDetailsWithoutHandlers,
    userSession: userId
  };
  const pageLoadStartTime = useRef<number>(new Date().getTime());
  const previousTabRef = useRef<OrderFlowTabs | null>(null);
  
  useEffect(() => {
    const pageLoadEndTime = new Date().getTime();
    const pageLoadTime = pageLoadEndTime - pageLoadStartTime.current;
    SessionManager.trackPage(window.location.href);
    SessionManager.incrementPageVisitCount();

    if (previousTabRef.current !== null) {
      SessionManager.setOriginTab(OrderFlowTabs[previousTabRef.current]);
    }
    
    const sessionPayload = createSessionInfo(pageLoadTime, query);
    AnalyticsService.sendLog(OrderFlowTabs[currentTab], sessionPayload);
    
    previousTabRef.current = currentTab;
  }, [currentTab, query]);

  useEffect(() => {
    if (token?.length) {
      const tokenString = token.toString();
      dispatch(getUserDetails(tokenString));
    }
  }, [token]);

  useEffect(() => {
    if (userApiCall.loading === 'pending') {
      navigateToNextTab(dispatch, OrderFlowTabs.Loading);
    } else if (
      userApiCall.loading === 'succeeded' &&
      userApiCall.response.httpCode === 200
    ) {
      dispatch(setOrderDetails(userApiCall.response.userDetails as OrderFlow));
      navigateToNextTab(dispatch, OrderFlowTabs.PersonalDetailsConfirmation);
    } else if (
      userApiCall.loading === 'succeeded' &&
      userApiCall.response.httpCode === 401
    ) {
      setErrorMessage('token-expired-error');
      navigateToNextTab(dispatch, OrderFlowTabs.Error);
    }
  }, [userApiCall.loading, userApiCall.response]);

  useEffect(() => {
    if (extendedInsurance === 'true') {
      navigateToNextTab(dispatch, OrderFlowTabs.ExtensionFlow);
    }
  }, [extendedInsurance]);

  useEffect(() => {
    if (
      checkIdRepetitionApiCall.loading === 'succeeded' &&
      checkIdRepetitionApiCall.response.httpCode === 200 &&
      checkIdRepetitionApiCall.response.ch === true
    ) {
      dispatch(postOrderDetails(orderDetailsWithUserSession));
    } else if (
      checkIdRepetitionApiCall.loading === 'succeeded' &&
      !checkIdRepetitionApiCall.response.ch
    ) {
      setErrorMessage('duplicated-user-error');
      navigateToNextTab(dispatch, OrderFlowTabs.Error);
    }
  }, [checkIdRepetitionApiCall.loading, checkIdRepetitionApiCall.response]);

  useEffect(() => {
    if (
      orderApiCall.loading === 'succeeded' &&
      orderApiCall.response.httpCode === 200
    ) {
      dispatch(resetOrderDetails());
      navigateToNextTab(dispatch, OrderFlowTabs.Payment);
    } else if (orderApiCall.loading === 'pending') {
      navigateToNextTab(dispatch, OrderFlowTabs.Loading);
    }
  }, [orderApiCall.loading, orderApiCall.response]);

  const tabComponents = useMemo(() => ({
    [OrderFlowTabs.PersonalDetails]: <PersonalDetails />,
    [OrderFlowTabs.TravelDetails]: <TravelDetails />,
    [OrderFlowTabs.IdentificationDetails]: <IdentificationDetails />,
    [OrderFlowTabs.ExtraPersonalDetails]: <ExtraPersonalDetails />,
    [OrderFlowTabs.PersonalDetailsConfirmation]: <PersonalDetailsConfirmation />,
    [OrderFlowTabs.HealthCard]: <HealthCard />,
    [OrderFlowTabs.ExtendedInsurance]: <ExtendedInsurance />,
    [OrderFlowTabs.OrderSummary]: <OrderSummary />,
    [OrderFlowTabs.ExtensionFlow]: <ExtensionFlow />,
    [OrderFlowTabs.Payment]: <Payment />,
    [OrderFlowTabs.Loading]: <LoadingTab />,
    [OrderFlowTabs.Error]: <ErrorTab error={errorMessage} />
}), [errorMessage]);

  return tabComponents[currentTab] || <div>Default</div>;
};

export default FormController;
