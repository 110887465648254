import { useState } from 'react';
import StepWrapper from '../stepWrapper';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import styled from 'styled-components';
import {
  setTab,
  OrderFlowTabs,
  setExtendedInsurance,
  OrderFlow,
  setLegalConsentOne,
  setNewsConsent
} from '../../../redux/orderFlow/orderFlowSlice';
import { CheckBoxGroup, Box } from 'grommet';
import { MEDIA_URL } from '../../template/main-template/main-templateStyles';
import Tooltip from '../../atoms/tooltip/tooltip';
import { Products } from '../../../models/order-details';
import { StepFormWrapper } from '../../molecules/form-tab-presenter/form-tabStyles';
import { useTranslation } from 'react-i18next';
import {
  SPORTS_PACKAGE_PRICE,
  HOLIDAYS_PACKAGE_PRICE
} from '../../../utils/prices-calculator';
import { navigateToNextTab } from '../../../utils/navigation-helpers';
const ExtendedInsurance = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentFlow = useAppSelector(state => state.orderFlow.flow);
  const products = useAppSelector(state => state.orderFlow.products);
  const checkSportsPackage = useAppSelector(
    state => state.orderFlow.checkSportsPackage
  );
  const checkHolidayPackage = useAppSelector(
    state => state.orderFlow.checkHolidayPackage
  );
  function convertStringToEnum(value: string): Products | null {
    const num = Number(value);
    if (!isNaN(num) && num in Products) {
      return num;
    }
    return null;
  }
  const ProductEnum = products && convertStringToEnum(products);
  function showSportsPackage(): boolean {
    if (currentFlow === OrderFlow.Flow1) {
      return true;
    } else if (currentFlow === OrderFlow.Flow2) {
      if (checkSportsPackage) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
  function showHolidayPackage(): boolean {
    if (currentFlow === OrderFlow.Flow1) {
      return true;
    } else if (currentFlow === OrderFlow.Flow2) {
      if (checkHolidayPackage) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  const options = [
    showSportsPackage() && {
      id: 'checkbox-sportsPackage',
      label: t('checkbox-sportsPackage', { PRICE: SPORTS_PACKAGE_PRICE }),
      value: 'sports'
    },
    showHolidayPackage() && {
      id: 'checkbox-showHolidayPackage',
      label: t('checkbox-holidayPackage', { PRICE: HOLIDAYS_PACKAGE_PRICE }),
      value: 'holiday'
    }
  ].filter(Boolean);
  const [value, setValue] = useState<string[]>([]);
  const handleContinue = () => {
    const sportsPackage = value.includes('sports');
    const holidayPackage = value.includes('holiday');

    dispatch(setExtendedInsurance({ sportsPackage, holidayPackage }));
    navigateToNextTab(dispatch, OrderFlowTabs.OrderSummary);
  };
  const handleBack = () => {
    if (currentFlow === OrderFlow.Flow1) {
      navigateToNextTab(dispatch, OrderFlowTabs.HealthCard);
    } else {
      dispatch(setLegalConsentOne({ legalConsentOne: false }));
      dispatch(setNewsConsent({ newsConsent: false }));
      navigateToNextTab(dispatch, OrderFlowTabs.PersonalDetailsConfirmation);
    }
  };

  const nextLabel = value.length > 0 ? 'NEXT' : 'NOT-NOW';

  return !showSportsPackage() && !showHolidayPackage() ? (
    <StepFormWrapper>
      <OptionalText>{t('form-sub-tab-5-allextended')}</OptionalText>
    </StepFormWrapper>
  ) : (
    <StepWrapper
      next={{ f: handleContinue, label: nextLabel }}
      back={{ f: handleBack, label: 'BACK' }}
    >
      {showSportsPackage() && (
        <Tooltip
          children={
            <Pack>
              <PackImage src={`${MEDIA_URL}/pack_sports.webp`} />
              <PackText>{t('form-sub-tab-5-sports')}</PackText>
            </Pack>
          }
          tooltipText={t('tooltip-sport')}
        />
      )}
      {showHolidayPackage() && (
        <Tooltip
          children={
            <Pack>
              <PackImage src={`${MEDIA_URL}/pack_holiday.webp`} />
              <PackText>{t('form-sub-tab-5-holiday')}</PackText>
            </Pack>
          }
          tooltipText={t('tooltip-holiday')}
        />
      )}
      <Box align='center'>
        <CheckBoxGroupStyled
          align='left'
          options={options.map((option: any) => option)}
          gap='xsmall'
          value={value}
          onChange={(e: any) => setValue(e.value)}
        />
        <ExtraText>{t('checkbox-extraText')}</ExtraText>
      </Box>
    </StepWrapper>
  );
};

export default ExtendedInsurance;
const ExtraText = styled.p`
  color: white;
  font-size: 14px;
`;
const OptionalText = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 800;
`;
const PackText = styled.p`
  color: white;
`;
const PackImage = styled.img`
  height: 99px;
`;
const Pack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckBoxGroupStyled = styled(CheckBoxGroup)`
  svg {
    stroke: #06b192;
  }
  div {
    div {
      border: 2px solid #06b192;
      border-radius: 10%;
      align: center;
    }
  }
  label {
    color: white;
    font-size: 18px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
`;
