import { AWSFirehose, DeviceType, Event, Logger, Console } from "@beway-tech/bda-event-library";
import { OncampusOrderFlowPayload, OncampusLandingPayload, AnalyticsPayload, SessionInfo } from "./payload";

type OncampusPayload = OncampusOrderFlowPayload | OncampusLandingPayload;

const Environment = (window as any).OPSEEKER_CONFIG.ENVIRONMENT;
const awsConfigParams = {
  //@ts-ignore
  region: window.OPSEEKER_CONFIG.REGION,
  //@ts-ignore
  identityPoolId: window.OPSEEKER_CONFIG.IDENTITY_POOL_ID,
  //@ts-ignore
  deliveryStream: window.OPSEEKER_CONFIG.DELIVERY_STREAM_NAME,
};

export class AnalyticsService {
  private logger: Logger<OncampusPayload>;

  constructor() {
    const firehose = new AWSFirehose(awsConfigParams);
    this.logger = new Logger<OncampusPayload>().addTransport(firehose);

    if (Environment === 'staging' || Environment === 'qa') {
      this.logger.addTransport(new Console<OncampusPayload>());
    }
  }

  public async sendLog(tab: string, sessionInfo: SessionInfo): Promise<void> {
    const deviceType = sessionInfo.isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP;
    const payloadData: AnalyticsPayload = {
      sessionInfo: { ...sessionInfo },
      tab,
      userUUID: sessionInfo.userId,
      deviceType,
      type: 'orderFlow',
    };
    const eventPayload = new OncampusOrderFlowPayload(payloadData);
    await this.logger.send(new Event<OncampusOrderFlowPayload>(eventPayload));
  }

  public async sendLandingLog(sessionInfo: SessionInfo): Promise<void> {
    const deviceType = sessionInfo.isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP;
    const payloadData: AnalyticsPayload = {
      sessionInfo: { ...sessionInfo },
      userUUID: sessionInfo.userId,
      deviceType,
      type: 'landing',
    };
    const eventPayload = new OncampusLandingPayload(payloadData);
    await this.logger.send(new Event<OncampusLandingPayload>(eventPayload));
  }
}

export default new AnalyticsService();
