import React, { useState } from 'react';
import { characteristics } from '../../../models/services';
import {
  Wrapper,
  Characteristics,
  Characteristic,
  Title,
  Icon,
  Description,
  TitleWrapper,
  SubtitleWrapper,
  Leyend,
  CloseButton,
} from './CoveragesStyles';
import { useTranslation } from 'react-i18next';
import { renderTextWithStrongAndParagraphs } from '../../../utils/renderTextWithStrongAndParagraphs';
import { SessionManager } from '../../../utils/session-manager';
import AnalyticsService from '../../../analytics/AnalyticsService';
import { createSessionInfo } from '../../../analytics/payload';
import { logError } from '../../../utils/log-utils';

export const Coverages = () => {
  const { t } = useTranslation();
  return (
    <Wrapper id="coverages" data-tracking="true">
      <TitleWrapper>
        <h2>{renderTextWithStrongAndParagraphs(t('landing.coverages.title'))}</h2>
      </TitleWrapper>
      <SubtitleWrapper>
        <h3>{renderTextWithStrongAndParagraphs(t('landing.coverages.subtitle'))}</h3>
      </SubtitleWrapper>

      <Characteristics>
        {characteristics[0].EN.map((c, i) => (
          <RenderCharacteristics 
            c={c} 
            i={i} 
            t={t} 
            key={`characteristic-${c.text}`} 
          />
        ))}
      </Characteristics>
      <Leyend>
        <p>
          {t('landing.coverages.leyend1')}{' '}
          <a 
            href={t('landing.coverages.leyend-link')} 
            target='_blank' 
            rel='noopener noreferrer'
            id="coverage_leyend_link"
            onClick={handleLeyendClick}
          >
            {t('landing.coverages.leyend2')}
          </a>
        </p>
      </Leyend>
    </Wrapper>
  );
};

const handleLeyendClick = async () => {
  // Track legend link click
  const trackingId = "coverage_leyend_link";
  SessionManager.setButtonId(trackingId);
  
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.toString();
    const sessionInfo = createSessionInfo(0, query);
    await AnalyticsService.sendLandingLog(sessionInfo);
  } catch (error) {
    logError('Error sending coverage legend analytics:', error);
  }
};

const RenderCharacteristics = ({ c, i, t }: { c: any; i: any; t: any }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const trackingId = `coverage_characteristic_${i}`;

  const handleClick = async () => {
    setIsExpanded(!isExpanded);
    
    SessionManager.setButtonId(trackingId);
    
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.toString();
      const sessionInfo = createSessionInfo(0, query);
      await AnalyticsService.sendLandingLog(sessionInfo);
    } catch (error) {
      logError('Error sending coverage analytics:', error);
    }
  };

  return (
    <Characteristic
      index={i}
      key={i}
      title={t(`landing.coverages.${c.text}`)}
      id={trackingId}
      isExpanded={isExpanded}
      onClick={handleClick}
      data-tracking="true"
    >
      {!isExpanded && <Icon fileName={c.icon} />}
      <Title>{t(`landing.coverages.${c.text}`)}</Title>
      {isExpanded && <Description>{t(`landing.coverages.description.${c.text}`)}</Description>}
      {isExpanded && <CloseButton onClick={handleClick} id={`${trackingId}_close`} />}
    </Characteristic>
  );
};
