import { AnalyticsPayload } from "./AnalyticsPayload";
import { BaseOncampusPayload } from './OncampusBasePayload';


/**
 * OncampusLandingPayload represents analytics data specifically for landing page events.
 * 
 * This class extends BaseOncampusPayload. It's used by the AnalyticsService to send 
 * landing page analytics events to AWS Firehose for data processing and analysis.
 */
export class OncampusLandingPayload extends BaseOncampusPayload {
  constructor(payload: AnalyticsPayload) {
    super(payload);
  }
}
