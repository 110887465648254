import { Grommet } from 'grommet';
import { useEffect } from 'react';
import CookieBot from 'react-cookiebot';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './amplify-configure';
import { FrequentQuestions } from './components/organisms/frequent-questions/frequentQuestions';
import { LandingPage } from './components/pages/landing/landing-page';
import { CookiesPolicy } from './components/pages/legal/cookies-policy';
import { LegalNotice } from './components/pages/legal/legal-notice';
import { PrivacyPolicy } from './components/pages/legal/privacy-policy';
import { NewFlowPage } from './components/pages/order-flow/newFlow-page';
import { PriceTagPage } from './components/pages/price-tag/price-tag-page';
import { PurchasePage as PurchaseResultPage } from './components/pages/purchase-result/purchase-result-page';
import { VersionPage } from './components/pages/version/version-page';
import { MainTemplate } from './components/template/main-template/main-template';
import { GlobalStyle } from './components/template/main-template/main-templateStyles';
import { saveQueryParams } from './utils/saveQueryParams';
import { SessionManager } from './utils/session-manager';
import NotFound from './components/pages/notFound/NotFound';
import { Contact } from './components/organisms/contact/contact';
import { Chatbot } from './components/chatbot/chatbot';
import { theme } from './utils/theme';

export const App = () => {
  useEffect(() => {
    saveQueryParams(window.location.search);
    SessionManager.setUserId();
  }, []);

  return (
    <Grommet theme={theme} full>
      <Router>
        <CookieBot domainGroupId='382b8002-e8ef-4fef-aab3-d944bb34abe6' />;
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/version' element={<VersionPage />} />
          <Route path='/onboarding' element={<NewFlowPage />} />
          <Route
            path='/faq'
            element={
              <MainTemplate>
                <FrequentQuestions />
              </MainTemplate>
            }
          />
          <Route
            path='/contact'
            element={
              <MainTemplate>
                <Contact />
              </MainTemplate>
            }
          />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/legal-notice' element={<LegalNotice />} />
          <Route path='/cookies-policy' element={<CookiesPolicy />} />
          <Route path='/pricetag/:orderId' element={<PriceTagPage />} />
          <Route path='/purchase/:state' element={<PurchaseResultPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      <GlobalStyle />
      <Chatbot />
    </Grommet>
  );
};
