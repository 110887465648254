import styled from 'styled-components';
import { MEDIA_URL } from '../../template/main-template/main-templateStyles';
import { device } from '../../../utils/use-device-type';

interface CharacteristicProps {
  index: number;
  title: null | string;
  isExpanded?: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.landing.colors.secondary};
  padding: 40px 20px 120px 20px;
  color: #f4f4f4;
  text-align: center;
  > h2 {
    color: #f4f4f4;
  }

  @media (${device.tablet}) {
    padding: 100px 50px 150px 50px;
  }
`;

export const Characteristics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px auto;
  @media (${device.tablet}) {
    width: 100%;
    margin: 40px auto;
    row-gap: 10px;
    max-width: 1000px;
  }
  @media (${device.laptopL}) {
    max-width: 1200px;
  }
`;

export const Characteristic = styled.div<CharacteristicProps>`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isExpanded ? 'row' : 'column')};
  width: ${(props) => (props.isExpanded ? '100%' : '33%')};
  max-width: 330px;
  cursor: pointer;
  background: ${(props) => props.isExpanded && props.theme.landing.colors.fifth};
  :hover {
    background: ${(props) => props.theme.landing.colors.fifth};
  }
  padding: ${(props) => (props.isExpanded ? '10px' : '0')};
  @media (${device.tablet}) {
    justify-content: ${(props) => (props.isExpanded ? 'space-around' : 'center')};
    flex-direction: column;
    width: 260px;
    max-width: 260px;
    height: 220px;
  }
`;
export const Description = styled.p`
  font-size: 0.7rem;
  line-height: 130%;
  margin: 0 auto;
  padding: 10px 0;
  max-width: 200px;
  text-align: center;
  @media (${device.tablet}) {
    font-size: 0.9rem;
    max-width: 210px;
  }
`;

export const Title = styled.p`
  color: ${props => props.theme.landing.colors.third};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  line-height: 110%;
  max-width: 200px;
  margin: 0 auto;
  font-weight: bold;

  @media (${device.tablet}) {
    padding-top: 8px;
    font-size: 1rem;
    max-width: 210px;
  }
`;

export const Icon = styled.p<{ fileName: string }>`
  background: url('${MEDIA_URL}/icons/${props => props.fileName}.svg') center
    no-repeat;
  background-size: contain;
  height: 35px;
  width: 35px;
  margin: 10px auto 0 auto;
  display: inline-block;
  margin-bottom: 10px;
  @media (${device.tablet}) {
    height: 50px;
    width: 50px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background: url('/assets/close-button.png') center no-repeat;
  background-size: contain;
  @media (${device.tablet}) {
    background: none;
  }
`;

export const TitleWrapper = styled.div`
  h2 {
    color: white;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      gap: 10px;
      line-height: normal;
    }
  }
`;

export const SubtitleWrapper = styled.div`
  h3 {
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
    line-height: normal;

    strong {
      font-weight: 700;
    }
  }
`;

export const Leyend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1rem;

  p {
    max-width: 400px;
  }

  @media (min-width: 768px) {
    margin-bottom: 60px;
    p {
      max-width: 100%;
    }
  }
`;
