import { WebPageEventPayload } from "@beway-tech/bda-event-library";
import { AnalyticsPayload } from "./AnalyticsPayload";

/**
 * BaseOncampusPayload represents common analytics data for Oncampus events.
 * 
 * This class extends WebPageEventPayload from the BDA event library and implements the
 * AnalyticsPayload interface. It's designed to capture and format data for tracking
 * user interactions and session information.
 * 
 * It serves as the base class for specific payload types like landing page or order flow events.
 */
export class BaseOncampusPayload extends WebPageEventPayload implements Omit<AnalyticsPayload, "tab"> {
  sessionInfo!: AnalyticsPayload["sessionInfo"];
  userUUID!: AnalyticsPayload["userUUID"];
  
  constructor(payload: AnalyticsPayload) {
    super();
    if (payload) {
      Object.assign(this, payload);
    }
  }
}