import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import StepWrapper from '../stepWrapper';
import {
  OrderFlowTabs,
  setIdentificationDetails,
  setTab
} from '../../../redux/orderFlow/orderFlowSlice';
import { NewFlowBox } from './PersonalDetails';
import { SelectInput } from '../../atoms/select-input/select-input';
import { getDocumentTypes } from '../../../models/document-types';
import { DatePicker } from '../../atoms/date-picker/date-picker';
import { TextInput } from '../../atoms/text-input/text-input';
import {
  validateString,
  validatePastDate,
  limitDocumentNumber
} from '../../../utils/form-validator';
import {
  getEighteenYearsAgoDate,
  getSixtyNineYears364DaysAgoDate
} from '../../atoms/date-picker/date-picker-functions';
import { getLanguage } from '../../../utils/i18n-utils';
import { navigateToNextTab } from '../../../utils/navigation-helpers';

const IdentificationDetails = () => {
  const dispatch = useAppDispatch();
  const language = getLanguage();
  const [documentType, setDocumentType] = useState<string>(
    useAppSelector(state => state.orderFlow.documentType) || ''
  );
  const [documentNumber, setDocumentNumber] = useState<string>(
    useAppSelector(state => state.orderFlow.documentNumber) || ''
  );
  const [birthday, setBirthday] = useState<string>(
    useAppSelector(state => state.orderFlow.birthday) || ''
  );
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [documentNumberError, setDocumentNumberError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const validate = () => {
    const documentTypeIsValid = validateString(documentType);
    const documentNumberIsValid = validateString(documentNumber);
    const birthdayIsValid = validatePastDate(birthday);
    setDocumentTypeError(!documentTypeIsValid);
    setDocumentNumberError(!documentNumberIsValid);
    setBirthdayError(!birthdayIsValid);
    return documentTypeIsValid && documentNumberIsValid && birthdayIsValid;
  };
  const handleContinue = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        setIdentificationDetails({
          documentType,
          documentNumber,
          birthday
        })
      );
      navigateToNextTab(dispatch, OrderFlowTabs.ExtraPersonalDetails);
    }
  };
  const handleBack = () => {
    navigateToNextTab(dispatch, OrderFlowTabs.TravelDetails);
  };
  return (
    <StepWrapper
      next={{ f: handleContinue, label: 'NEXT' }}
      back={{ f: handleBack, label: 'BACK' }}
    >
      <NewFlowBox>
        <SelectInput
          field={{
            id: 'documentType',
            type: 'document-type',
            mandatory: true
          }}
          options={getDocumentTypes(language)}
          onChange={setDocumentType}
          value={documentType}
          warning={documentTypeError}
        />
        <TextInput
          field={{ id: 'documentNumber', type: 'string', mandatory: true }}
          onChange={e => setDocumentNumber(limitDocumentNumber(e))}
          value={documentNumber}
          warning={documentNumberError}
          maxLength={32}
        />
        <DatePicker
          maxDate={{ enabled: true, date: getEighteenYearsAgoDate() }}
          minDate={{ enabled: true, date: getSixtyNineYears364DaysAgoDate() }}
          field={{ id: 'birthday', type: 'birthday', mandatory: true }}
          onChange={setBirthday}
          value={birthday}
          warning={birthdayError}
        />
      </NewFlowBox>
    </StepWrapper>
  );
};

export default IdentificationDetails;
