import { AppDispatch } from '../redux/store';
import { OrderFlowTabs, setTab } from '../redux/orderFlow/orderFlowSlice';
import { SessionManager } from './session-manager';

export const navigateToNextTab = (
  dispatch: AppDispatch,
  nextTab: OrderFlowTabs
): void => {
  SessionManager.setDestinationTab(OrderFlowTabs[nextTab]);
  dispatch(setTab(nextTab));
};
