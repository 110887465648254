import styled from 'styled-components';
import { SessionManager } from '../../../utils/session-manager';
import { device } from '../../../utils/use-device-type';
import React, { ReactNode } from 'react';
import AnalyticsService from '../../../analytics/AnalyticsService';
import { createSessionInfo } from '../../../analytics/payload';
import { logError } from '../../../utils/log-utils';

export const DropDown = (props: {
  id: number;
  title: string;
  content: ReactNode[];
  expanded: null | number;
  setExpanded: any;
  category?: string;
}) => {
  const isExpanded = props.expanded === props.id;
  const trackingId = props.category ? 
    `dropdown_${props.category}_${props.id}` : 
    `dropdown_${props.id}`;

  const handleDropdownClick = async (e: React.MouseEvent) => {
    props.setExpanded(isExpanded ? null : props.id);
    SessionManager.setButtonId(trackingId);
    
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.toString();
      const sessionInfo = createSessionInfo(0, query);
      await AnalyticsService.sendLandingLog(sessionInfo);
    } catch (error) {
      logError('Error sending FAQ analytics:', error);
    }
    
  };
  
  return (
    <Wrapper id={trackingId} onClick={handleDropdownClick} data-tracking="true">
      <Title>
        <TitleLabel expanded={isExpanded}>{props.title}</TitleLabel>
        <p>
          <ButtonNaked
            id={`${trackingId}_button`}
            expanded={isExpanded}
          >
            {isExpanded ? '-' : '+'}
          </ButtonNaked>
        </p>
      </Title>
      {isExpanded && (
        <Content>
          {props.content.map((element, index) => (
            <React.Fragment key={index}>{element}</React.Fragment>
          ))}
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px;
  border-bottom: 1px solid #e7e2df;
  @media (min-width: 380px) {
    padding: 8px 30px 8px 20px;
  }
  @media (min-width: 991px) {
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
`;

const Content = styled.div`
  > p {
    max-width: 90%;
    text-align: left;
    font-size: 0.8rem;
  }
  @media ${device.tablet} {
    > p {
      font-size: 1rem;
      padding-left: 30px;
    }
  }
`;

export const ButtonNaked = styled.span<{ expanded?: boolean }>`
  display: block;
  background: transparent;
  padding: 0;
  margin: 0;
  min-width: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${props =>
    props.expanded
      ? props.theme.landing.colors.primary
      : props.theme.landing.colors.third};
`;

export const TitleLabel = styled.p<{ expanded: boolean }>`
  max-width: 90%;
  font-size: 0.8rem;
  text-align: left;
  color: ${props =>
    props.expanded
      ? props.theme.landing.colors.third
      : props.theme.landing.colors.primary};
  @media (min-width: 380px) {
    font-size: 1rem;
  }
`;
