import React, { useState, useEffect } from 'react';
import './styles.css';
import Lottie from 'lottie-react';
import success from '../../components/Lottie/LottieJson/success.json';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const handleRecaptchaChange = (value: string | null) => {
    if (value) {
      console.log('reCAPTCHA verification successful');
      setRecaptchaValidated(true);
    } else {
      console.log('reCAPTCHA verification failed');
      setRecaptchaValidated(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'wf_script';
    script.src =
      'https://bigin.zoho.eu/crm/WebformScriptServlet?rid=61e65392287c4a3ab854bd04dbc7e29294d3b87f919590a1b7a4e3a577ecd9c06a787c7e542ce956da4eacb4c9675f6cgid38112c502d4865bde2a512fdf44479a40a34602da24128ad20956cc5b3b35acd';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const removeError = (field: HTMLInputElement | HTMLTextAreaElement) => {
    const parentElement = field.parentElement?.parentElement;
    const errorElement = parentElement?.querySelector('.wf-field-error');
    if (errorElement) {
      parentElement?.classList.remove('wf-field-error-active');
      errorElement.remove();
    }
  };

  const setError = (field: HTMLInputElement | HTMLTextAreaElement, label: string) => {
    const parentElement = field.parentElement?.parentElement;
    let errorElement = parentElement?.querySelector('.wf-field-error');
    if (!errorElement) {
      errorElement = document.createElement('SPAN');
      errorElement.setAttribute('class', 'wf-field-error');
      errorElement.innerHTML = label;
      parentElement?.append(errorElement);
      parentElement?.classList.add('wf-field-error-active');
    }
  };

  const validateFields = (): boolean => {
    //@ts-ignore
    const form = document.forms['BiginWebToRecordForm691033000000504002'] as HTMLFormElement;
    const validateFields = form.querySelectorAll('[fvalidate=true]') as NodeListOf<
      HTMLInputElement | HTMLTextAreaElement
    >;
    let isValid = true;

    validateFields.forEach((field) => {
      const value = field.value.trim();
     
            if (!value.match(/^([A-Za-z0-9-._%'+/]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,22})$/)) {
              setError(field, `Enter a valid Email`);
              isValid = false;
            }
    });

    return isValid;
  };

  const privacyError = (): boolean => {
    const privacyCheck = document.getElementById(
      'privacycheck691033000000504002'
    ) as HTMLInputElement;
    if (privacyCheck && !privacyCheck.checked) {
      setError(
        privacyCheck,
        'Please accept privacy policy and terms and conditions'
      );
      return false;
    }
    return true;
  };

  const disablePrivacyError = () => {
    const privacyCheck = document.getElementById(
      'privacycheck691033000000504002'
    ) as HTMLInputElement;
    removeError(privacyCheck);
  };

  const checkMandatory = (): boolean => {
    let isValid = true;
    const mandatoryFields = ['Last Name'];
    const fieldLabels = ['Name'];

    mandatoryFields.forEach((field, index) => {
      //@ts-ignore
      const fieldObj = document.forms['BiginWebToRecordForm691033000000504002'][field];
      if (fieldObj) {
        const value = (fieldObj as HTMLInputElement).value.trim();
        if (!value) {
          setError(fieldObj as HTMLInputElement, `${fieldLabels[index]} cannot be empty`);
          isValid = false;
        } else if (
          fieldObj.nodeName === 'SELECT' &&
          (fieldObj as HTMLSelectElement).value === '-None-'
        ) {
          setError(fieldObj as HTMLInputElement, `${fieldLabels[index]} cannot be empty`);
          isValid = false;
        } else if (
          (fieldObj as HTMLInputElement).type === 'checkbox' &&
          !(fieldObj as HTMLInputElement).checked
        ) {
          setError(fieldObj as HTMLInputElement, `Please accept ${fieldLabels[index]}`);
          isValid = false;
        }
      }
    });

    if (!privacyError()) isValid = false;
    if (!validateFields()) isValid = false;

    if (!recaptchaValidated) {
      console.log('reCAPTCHA validation failed');
      isValid = false;
    }

    if (!isValid) {
      const errorElement = document.querySelector('.wf-field-error');
      if (errorElement) {
        const inputElement = errorElement.parentElement?.querySelector('input, select') as
          | HTMLInputElement
          | HTMLSelectElement;
        inputElement?.focus();
      }
    } else {
      (document.getElementById('formsubmit') as HTMLInputElement).disabled = true;
    }

    return isValid;
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Form submission initiated');
    if (checkMandatory()) {
      console.log('Form validation passed');
      //@ts-ignore
      const form = document.forms['BiginWebToRecordForm691033000000504002'] as HTMLFormElement;
      try {
        const iframe = document.querySelector('iframe[name="hidden691033000000504002Frame"]');
        const formSubmission = new Promise<void>((resolve, reject) => {
          iframe!.addEventListener('load', () => resolve());
          form.submit();
        });
        await formSubmission;
        setFormSubmitted(true);
      } catch (error) {
        console.error('Form submission failed', error);
      }
    } else {
      console.log('Form validation failed');
    }
  };

  return (
    <div className='wf-parent' style={{ backgroundColor: '#FFFFFF' }}>
      {!formSubmitted ? (
        <div className='wf-wrapper' id='BiginWebToRecordFormDiv691033000000504002'>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <form
            id='BiginWebToRecordForm691033000000504002'
            name='BiginWebToRecordForm691033000000504002'
            className='wf-form-component'
            data-ux-form-alignment='top'
            style={{ fontFamily: 'Arial', position: 'relative', fontSize: '15px' }}
            method='POST'
            encType='multipart/form-data'
            target='hidden691033000000504002Frame'
            onSubmit={handleSubmit}
            acceptCharset='UTF-8'
            action='https://bigin.zoho.eu/crm/WebForm'
          >
            <input
              type='text'
              style={{ display: 'none' }}
              name='xnQsjsdp'
              onChange={() => {}}
              value='38112c502d4865bde2a512fdf44479a40a34602da24128ad20956cc5b3b35acd'
            />
            <input type='hidden' name='zc_gad' id='zc_gad' value='' />
            <input
              type='text'
              style={{ display: 'none' }}
              name='xmIwtLD'
              onChange={() => {}}
              value='61e65392287c4a3ab854bd04dbc7e29294d3b87f919590a1b7a4e3a577ecd9c06a787c7e542ce956da4eacb4c9675f6c'
            />
            <input
              type='text'
              style={{ display: 'none' }}
              name='actionType'
              onChange={() => {}}
              value='Q29udGFjdHM='
            />
            <input type='hidden' name='rmsg' id='rmsg' onChange={() => {}} value='true' />
            <input
              type='text'
              style={{ display: 'none' }}
              onChange={() => {}}
              name='returnURL'
              value='null'
            />
            <h2 className='wf-header'>Contact us</h2>
            <div id='elementDiv691033000000504002' className='wf-form-wrapper'>
              <div className='wf-row'>
                <div className='wf-field wf-field-mandatory'>
                  <div className='wf-field-inner'>
                    <input
                      placeholder='Name'
                      name='Last Name'
                      maxLength={80}
                      type='text'
                      id="contact-form-name"
                      className='wf-field-item wf-field-input'
                      onChange={() => {}}
                      onInput={(e) => removeError(e.target as HTMLInputElement)}
                    />
                  </div>
                </div>
              </div>
              <div className='wf-row'>
                <div className='wf-field'>
                  <div className='wf-field-inner'>
                    <input
                      //@ts-ignore
                      fvalidate='true'
                      ftype='email'
                      name='Email'
                      id="contact-form-email"
                      placeholder='Email'
                      maxLength={100}
                      type='text'
                      className='wf-field-item wf-field-input'
                      onInput={(e) => removeError(e.target as HTMLInputElement)}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div className='wf-row'>
                <div className='wf-field'>
                  <div className='wf-field-inner'>
                    <textarea
                      placeholder='Message'
                      name='Description'
                      id="contact-form-message"
                      className='wf-field-item wf-field-input'
                      onInput={(e) => removeError(e.target as HTMLTextAreaElement)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='wf-row'>
                <div className='wf-label'></div>
                <div className='wf-field'>
                  <div className='wf-field-inner flex-center-v'>
                    <input
                      className='wf-field-item wf-field-checkbox'
                      type='checkbox'
                      id='privacycheck691033000000504002'
                      name='privacycheck'
                      onChange={disablePrivacyError}
                    />
                    <label htmlFor='privacycheck691033000000504002' className='cP'>
                      <div dir='ltr'>
                        I agree with the{' '}
                        <a
                          href='https://www.oncampusabroad.com/privacy-policy'
                          id="contact-form-privacy-link"
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          privacy policy
                        </a>{' '}
                        and{' '}
                        <a
                          href='https://www.oncampusabroad.com/legal-notice'
                          id="contact-form-terms-link"
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          terms and conditions
                        </a>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ padding: '15px 0px 5px 0px' }}>
                <ReCAPTCHA
                  sitekey='6Lfou_ApAAAAAIpm22ePdsrruIE4CtUtJ0k6SXxf'
                  ref={recaptchaRef}
                  onChange={handleRecaptchaChange}
                />
              </div>
              <div className='wf-row wf-btn-row'>
                <div className='wf-label'></div>

                <div className='wform-btn-wrap'>
                  <input
                    type='submit'
                    id='formsubmit'
                    className='wf-btn'
                    style={{
                      backgroundColor: '#1980d8',
                      color: '#fff',
                      border: '1px solid #1980d8',
                      width: 'auto',
                    }}
                    value='Submit'
                  />
                </div>
              </div>
            </div>
          </form>
          <iframe
            title='formFrame'
            name='hidden691033000000504002Frame'
            style={{ display: 'none' }}
          ></iframe>
        </div>
      ) : (
        <div className='wf-wrapper-response' style={{ height: '461.797px' }}>
          <Lottie animationData={success} loop={false} style={{ height: 100 }} />
          <h2>Thank you for you message.</h2>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
