import { AnalyticsPayload } from "./AnalyticsPayload";
import { BaseOncampusPayload } from './OncampusBasePayload';

/**
 * OncampusOrderFlowPayload represents analytics data for order flow events.
 * 
 * This class extends BaseOncampusPayload and adds tab information to track 
 * which specific section of the order flow the user is interacting with.
 * It's used by the AnalyticsService to send order flow analytics events
 * to AWS Firehose for data processing and analysis.
 */
export class OncampusOrderFlowPayload extends BaseOncampusPayload {
  tab!: string;
  
  constructor(payload: AnalyticsPayload) {
    super(payload);
    this.tab = payload.tab || '';
  }
}
