import { Link } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import { Container, Img, Pack, Packs } from './productPolicyStyles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Triangle from '../../../assets/triangle.png';
import { renderTextWithStrongAndParagraphs } from '../../../utils/renderTextWithStrongAndParagraphs';

export const ProductPolicy = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <TitleWrapper>
        <h2>{renderTextWithStrongAndParagraphs(t('landing.productPolicy.title'))}</h2>
        <h2>{renderTextWithStrongAndParagraphs(t('landing.productPolicy.title2'))}</h2>
      </TitleWrapper>
      <SubtitleWrapper>
        <h3>{t('landing.productPolicy.subtitle')}</h3>
      </SubtitleWrapper>
      <Packs>
        <Pack type='sports'>
          <Img type='sports' />
          <div>
            <h3>{t('landing.productPolicy.pack-1-title')}</h3>
            <Text>
              {renderTextWithStrongAndParagraphs(t('landing.productPolicy.pack-1-paragraph'))}
            </Text>
          </div>
        </Pack>
        <Pack type='holiday'>
          <Img type='holiday' />
          <div>
            <h3>{t('landing.productPolicy.pack-2-title')}</h3>
            <Text>
              {renderTextWithStrongAndParagraphs(t('landing.productPolicy.pack-2-paragraph'))}
            </Text>
          </div>
        </Pack>
      </Packs>
      <WarningWrapper>
        <TriangleImg src={Triangle} alt='triangle' />
        <WarningText>{t('landing.productPolicy.warning')}</WarningText>
      </WarningWrapper>
      <p>{t('landing.productPolicy.paragraph-1')}</p>
      <ButtonsWrapper>
        <Link reloadDocument to='/onboarding?extendedInsurance=true'>
          <button
            id='ProductPolicy_extendMyInsurance_Button'
            onClick={() => SessionManager.setButtonId('ProductPolicy_extendMyInsurance_Button')}
          >
            {t('landing.productPolicy.button')}
          </button>
        </Link>
        <Anchor href={t('landing.productPolicy.link')} target='_blank' rel='noopener noreferrer' id='PackageConditions_link'>
          {t('landing.productPolicy.link-text')}
        </Anchor>
      </ButtonsWrapper>
    </Container>
  );
};

const TitleWrapper = styled.div`
  margin: 50px 0 10px 0;

  @media (min-width: 768px) {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  h2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: white;
    line-height: normal;
    margin-bottom: 0;

    @media (min-width: 768px) {
      justify-content: center;
      gap: 10px;
      color: white;
      line-height: normal;
    }
  }
`;

const SubtitleWrapper = styled.div`
  margin-bottom: 50px;

  h3 {
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
    line-height: normal;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  button {
    min-width: 300px;
  }
`;

const Anchor = styled.a`
  font-family: 'Merriweather', serif;
`;

const WarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
`;

const TriangleImg = styled.img`
  width: 80px;
  height: 70px;
  margin-left: 5px;
`;

const WarningText = styled.p`
  color: white;
  text-align: start;
  line-height: 1.3;
  font-size: 14px;
`;

const Text = styled.p`
  line-height: 1.3rem !important;
`;
