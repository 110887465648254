import {
  Wrapper,
  LegalPages,
  LegalProvider,
  ProviderLogo
} from './footerStyles';
import { RenderMenuItems } from '../../molecules/navigation-menu/navigation-menu';
import { FOOTER_NAVIGATION } from '../header/header-navigation';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  const HREF = 'https://www.ergo-segurosdeviaje.es/';

  return (
    <Wrapper>
      <LegalPages>
        <div id='social-media'>
          <a
            href='https://www.linkedin.com/company/oncampus-abroad/'
            target='_blank'
            rel='noreferrer'

          >
            <img
              src='https://main.oncampusabroad.com/media/images/linkedin.webp'
              width='50'
              height='50'
              id='linkedin' 
            />
          </a>
          <a
            href='https://www.instagram.com/oncampusabroad/'
            target='_blank'
            rel='noreferrer'

          >
            <img
              src='https://main.oncampusabroad.com/media/images/instagram.webp'
              width='50'
              height='50'
              id='instagram'
            />
          </a>
          <a
            href='https://www.facebook.com/oncampusabroad'
            target='_blank'
            rel='noreferrer'

          >
            <img
              src='https://main.oncampusabroad.com/media/images/facebook.webp'
              width='50'
              height='50'
              id='facebook'
            />
          </a>
        </div>
        <FooterNavigation pathDefs={FOOTER_NAVIGATION} />
      </LegalPages>
      <LegalProvider>
        <p>{t('landing.footer.copyright')}</p>

        <ProviderLogo href={HREF} target='_blank' rel='noopener noreferrer' id='ergo'>
          <span>{t('landing.footer.copyrightSpan')}</span>
        </ProviderLogo>
      </LegalProvider>
    </Wrapper>
  );
};

const FooterNavigation = (props: any) => {
  const { t } = useTranslation();
  return <RenderMenuItems {...props} t={t} />;
};
