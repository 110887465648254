import React from 'react';
import { Post } from '../../../models/blog';
import styled from 'styled-components';
import he from 'he';
import { device } from '../../../utils/use-device-type';
interface PostProps {
  post: Post;
  isEven: boolean;
}
interface PostImageProps {
  isEven: boolean;
}
const PostContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  width: 100%;
  border-radius: 15px;
  &.reverse {
    flex-direction: row-reverse;
  }

  @media ${device.tablet} {
    height: 170px;
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
    height: 551px;
    max-height: auto;
    &.reverse {
      flex-direction: column;
    }
  }
`;

const PostTitle = styled.h1`
  margin: 10px;
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5em;

  @media ${device.tablet} {
    font-size: 22px;
  }

  @media ${device.laptop} {
    font-size: 24px;
    max-height: 120px;
    -webkit-line-clamp: 3;
  }
`;

const PostLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.landing.colors.third} !important;
  margin: 10px;
  text-align: end;

  @media ${device.laptop} {
    text-align: start;
  }
`;

const DateText = styled.p`
  letter-spacing: 0px;
  color: #000000;
  text-decoration: none;
  margin: 10px;
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

const Box = styled.div`
  width: 150px;

  @media ${device.tablet} {
    height: 170px;
    width: 460px;
  }
  @media ${device.laptop} {
    width: 100%;
    height: auto;
  }
`;

const PostImage = styled.img<PostImageProps>`
  height: 130px;
  width: 150px;
  object-fit: cover;
  border-radius: ${props => (props.isEven ? '0 15px 15px 0' : '15px 0 0 15px')};
  @media ${device.tablet} {
    height: 170px;
    width: 100%;
  }

  @media ${device.laptop} {
    height: 266px;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
}

  @media ${device.laptop} {
    height: 100%;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
  }
`;

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const BlogPost: React.FC<PostProps> = ({ post, isEven }) => {
  const decodedTitle = he.decode(post.title.rendered);
  const readMore = he.decode('&rarr;Read more');

  return (
    <PostContainer className={isEven ? 'reverse' : ''}>
      <Box>
        <PostImage
          isEven={isEven}
          src={post.yoast_head_json.og_image[0].url}
          alt={post.title.rendered}
        />
      </Box>
      <BoxText>
        <DateText>{formatDate(post.date)}</DateText>
        <PostTitle>{decodedTitle}</PostTitle>
        <PostLink href={post.link} target='_blank' id='blog-post' rel='noopener noreferrer'>
          {readMore}
        </PostLink>
      </BoxText>
    </PostContainer>
  );
};

export default BlogPost;
