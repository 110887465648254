export const downloads = [
  {
    id: 'doc-table-of-contents',
    fileName: 'OnCampusAbroad_Table-of-Contents.pdf',
    icon: 'triangle',
    title: 'file-2',
    description: 'file-2-description',
  },
  {
    id: 'doc-packages',
    fileName: 'OnCampus_Extension-Packages_conditions.pdf',
    icon: 'plane',
    title: 'file-3',
    description: 'file-3-description',
  },
  {
    id: 'doc-ipid',
    fileName:
      'Insurance_Conditions_OnCampusAbroad.pdf?_gl=1*1gofet6*_gcl_au*MjExMjAxMTg2Mi4xNzMyNjM0Njk1*_ga*MTExMDkzODEzOS4xNzMyNjM0Njk0*_ga_CGYK4H4DMH*MTczMzIyMjgyNC42LjAuMTczMzIyMjgzMy41MS4wLjEzMTU4NTIyNjA',
    icon: 'umbrella',
    title: 'file-1',
    description: 'file-1-description',
  },
];
