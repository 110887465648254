import React, { useState, useEffect, useRef } from 'react';
import { Header } from '../../organisms/header-landing/header';
import { PageWrapper } from '../../template/main-template/main-templateStyles';
import { Services } from '../../organisms/services/Services';
import { FrequentQuestions } from '../../organisms/frequent-questions/frequentQuestions';
import { Coverages } from '../../organisms/coverages/Coverages';
import { Contact } from '../../organisms/contact/contact';
import { Footer } from '../../organisms/footer/footer';
import { TopBar } from '../../organisms/topbar/topbar';
import { ProductPolicy } from '../../organisms/productPolicy/packs';
import { Downloads } from '../../organisms/productPolicy/downloads';
import { PopularBlog } from '../../organisms/blog/popular-blog';
import { ErgoSection } from '../../organisms/ergo/ergoSection';
import { SessionManager } from '../../../utils/session-manager';
import AnalyticsService from '../../../analytics/AnalyticsService';
import { createSessionInfo } from '../../../analytics/payload';
import { logError } from '../../../utils/log-utils';


export const LandingPage = () => {
  const [isBlogLoaded, setIsBlogLoaded] = useState(false);
  const [isContactLoaded, setIsContactLoaded] = useState(false);
  const faqRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const pageLoadStartTime = useRef<number>(new Date().getTime());

  const handleBlogLoaded = () => {
    setIsBlogLoaded(true);
  };

  const handleContactLoaded = () => {
    setIsContactLoaded(true);
  };

  useEffect(() => {
    if (!SessionManager.getUserId()) {
      SessionManager.startSession();
    }
    const pageLoadEndTime = new Date().getTime();
    const pageLoadTime = pageLoadEndTime - pageLoadStartTime.current;
    SessionManager.trackPage(window.location.href);
    SessionManager.incrementPageVisitCount();
    SessionManager.setDestinationTab('');
    SessionManager.setOriginTab('');
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.toString();
    let sessionInfo = createSessionInfo(pageLoadTime, query);
    AnalyticsService.sendLandingLog(sessionInfo).catch((error) => {
      logError('Error sending page load analytics:', error);
    });
  }, []);

  useEffect(() => {
    let reference = undefined;
    const urlParams = new URLSearchParams(window.location.search);
    const sectionId = urlParams.get('id');

    if (sectionId) {
      if (sectionId === 'FAQ' && isBlogLoaded) {
        reference = faqRef;
      } else if (sectionId === 'CONTACT' && isContactLoaded) {
        reference = contactRef;
      }
      reference?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isBlogLoaded, isContactLoaded]);

  
  const handleLandingElementClick = async (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const target = e.target as HTMLElement;

    let currentElement: HTMLElement | null = target;
    let hasDropdownTracking = false;
    
    while (currentElement && !hasDropdownTracking) {
      if (
        currentElement.id && 
        (currentElement.id.startsWith('dropdown_') || 
         currentElement.id.startsWith('coverage_') ||
         currentElement.getAttribute('data-tracking') === 'true')
      ) {
        hasDropdownTracking = true;
      }
      currentElement = currentElement.parentElement;
    }
    
    if (target.id && 
        !hasDropdownTracking && 
        target.id !== "FAQ" && 
        target.id !== "coverages" && target.id !== "CONTACT") {
      SessionManager.setButtonId(target.id);
      
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.toString();
      let sessionInfo = createSessionInfo(0, query); // 0 for load time since this is a click event
      
      try {
        await AnalyticsService.sendLandingLog(sessionInfo);
      } catch (error) {
       logError('Error sending landing log:', error);
      }
    }
  };

  return (
    <PageWrapper onClick={handleLandingElementClick}>
      <TopBar blogLoaded={isBlogLoaded} />
      <Header />
      <Services />
      <Coverages />
      <ProductPolicy />
      <Downloads />
      <ErgoSection />
      <PopularBlog onLoad={handleBlogLoaded} />
      <FrequentQuestions ref={faqRef} />
      <Contact ref={contactRef} onLoad={handleContactLoaded} />
      <Footer />
    </PageWrapper>
  );
};
