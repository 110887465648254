import { getLanguage } from "../utils/i18n-utils";
export type Country = {
  c: string;
  en: string;
  es: string;
};
export const Countries = [
    {c: 'BE', en: 'Belgium', es: 'Bélgica'},
    {c: 'BG', en: 'Bulgary', es: 'Bulgaria'},
    { c: 'CZ', en: 'Czech Republic', es: 'República Checa' },
    { c: 'DK', en: 'Denmark', es: 'Dinamarca' },
    { c: 'DE', en: 'Germany', es: 'Alemania' },
    { c: 'EE', en: 'Estonia', es: 'Estonia' },
    { c: 'IE', en: 'Ireland', es: 'Irlanda' },
    { c: 'EL', en: 'Greece', es: 'Grecia' },
    { c: 'ES', en: 'Spain', es: 'España' },
    { c: 'FR', en: 'France', es: 'Francia' },
    { c: 'HR', en: 'Croatia', es: 'Croacia' },
    { c: 'IT', en: 'Italy', es: 'Italia' },
    { c: 'CY', en: 'Cyprus', es: 'Chipre' },
    { c: 'LV', en: 'Latvia', es: 'Letonia' },
    { c: 'LT', en: 'Lithuania', es: 'Lituania' },
    { c: 'LU', en: 'Luxembourg', es: 'Luxemburgo' },
    { c: 'HU', en: 'Hungary', es: 'Hungría' },
    { c: 'MT', en: 'Malta', es: 'Malta' },
    { c: 'NL', en: 'Netherlands', es: 'Países Bajos' },
    { c: 'AT', en: 'Austria', es: 'Austria' },
    { c: 'PL', en: 'Poland', es: 'Polonia' },
    { c: 'PT', en: 'Portugal', es: 'Portugal' },
    { c: 'RO', en: 'Romania', es: 'Rumanía' },
    { c: 'SI', en: 'Slovenia', es: 'Eslovenia' },
    { c: 'SK', en: 'Slovakia', es: 'Eslovaquia' },
    { c: 'FI', en: 'Finland', es: 'Finlandia' },
    { c: 'SE', en: 'Sweden', es: 'Suecia' }
]
export const OriginCountries: Country[] = [
    { c: 'AT', en: 'Austria', es: 'Austria' },
    { c: 'BE', en: 'Belgium', es: 'Bélgica'},
    { c: 'BG', en: 'Bulgary', es: 'Bulgaria'},
    { c: 'CZ', en: 'Czech Republic', es: 'República Checa' },
    { c: 'CY', en: 'Cyprus', es: 'Chipre' },
    { c: 'HR', en: 'Croatia', es: 'Croacia' },
    { c: 'DK', en: 'Denmark', es: 'Dinamarca' },
    { c: 'EE', en: 'Estonia', es: 'Estonia' },
    { c: 'FI', en: 'Finland', es: 'Finlandia' },
    { c: 'FR', en: 'France', es: 'Francia' },
    { c: 'EL', en: 'Greece', es: 'Grecia' },
    { c: 'HU', en: 'Hungary', es: 'Hungría' },
    { c: 'IE', en: 'Ireland', es: 'Irlanda' },
    { c: 'IS', en: 'Iceland', es: 'Islandia' },
    { c: 'IT', en: 'Italy', es: 'Italia' },
    { c: 'LV', en: 'Latvia', es: 'Letonia' },
    { c: 'LI', en: 'Liechtenstein', es: 'Liechtenstein' },
    { c: 'LT', en: 'Lithuania', es: 'Lituania' },
    { c: 'LU', en: 'Luxembourg', es: 'Luxemburgo' },
    { c: 'MT', en: 'Malta', es: 'Malta' },
    { c: 'NW', en: 'Norway', es: 'Noruega' },
    { c: 'NL', en: 'Netherlands', es: 'Países Bajos' },
    { c: 'PL', en: 'Poland', es: 'Polonia' },
    { c: 'PT', en: 'Portugal', es: 'Portugal' },
    { c: 'RO', en: 'Romania', es: 'Rumanía' },
    { c: 'SK', en: 'Slovakia', es: 'Eslovaquia' },
    { c: 'SI', en: 'Slovenia', es: 'Eslovenia' },
    { c: 'SE', en: 'Sweden', es: 'Suecia' },
]

export const DestinationCountries: Country[] = [
    { c: 'DE', en: 'Germany', es: 'Alemania' },
    { c: 'AT', en: 'Austria', es: 'Austria' },
    { c: 'BE', en: 'Belgium', es: 'Bélgica'},
    { c: 'BG', en: 'Bulgary', es: 'Bulgaria'},
    { c: 'CZ', en: 'Czech Republic', es: 'República Checa' },
    { c: 'CY', en: 'Cyprus', es: 'Chipre' },
    { c: 'HR', en: 'Croatia', es: 'Croacia' },
    { c: 'DK', en: 'Denmark', es: 'Dinamarca' },
    { c: 'EE', en: 'Estonia', es: 'Estonia' },
    { c: 'FI', en: 'Finland', es: 'Finlandia' },
    { c: 'FR', en: 'France', es: 'Francia' },
    { c: 'EL', en: 'Greece', es: 'Grecia' },
    { c: 'HU', en: 'Hungary', es: 'Hungría' },
    { c: 'IE', en: 'Ireland', es: 'Irlanda' },
    { c: 'IS', en: 'Iceland', es: 'Islandia' },
    { c: 'IT', en: 'Italy', es: 'Italia' },
    { c: 'LV', en: 'Latvia', es: 'Letonia' },
    { c: 'LI', en: 'Liechtenstein', es: 'Liechtenstein' },
    { c: 'LT', en: 'Lithuania', es: 'Lituania' },
    { c: 'LU', en: 'Luxembourg', es: 'Luxemburgo' },
    { c: 'MT', en: 'Malta', es: 'Malta' },
    { c: 'NW', en: 'Norway', es: 'Noruega' },
    { c: 'NL', en: 'Netherlands', es: 'Países Bajos' },
    { c: 'PL', en: 'Poland', es: 'Polonia' },
    { c: 'PT', en: 'Portugal', es: 'Portugal' },
    { c: 'GB', en: 'United Kingdom', es: 'Reino Unido' },
    { c: 'MK', en: 'The Republic of North Macedonia', es: 'República de Macedonia del Norte' },
    { c: 'RO', en: 'Romania', es: 'Rumanía' },
    { c: 'CS', en: 'Serbia', es: 'Serbia' },
    { c: 'SK', en: 'Slovakia', es: 'Eslovaquia' },
    { c: 'SI', en: 'Slovenia', es: 'Eslovenia' },
    { c: 'SE', en: 'Sweden', es: 'Suecia' },
    { c: 'CH', en: 'Switzerland', es: 'Suíza' },
    { c: 'TR', en: 'Turkey', es: 'Turquía' }
]

export const getCountries = (language : 'en' | 'es' | string, countries: Country[]) => countries.map(country => ({value : country.c, label : country[language as 'c' | 'en' | 'es']}))


export const parseCountryCode = (code: string) => {
    const language = getLanguage()
    const country = Countries.find(country => country.c === code)
    if (!country) {
        return ''
    }
    return country[language as 'c' | 'en' | 'es'];
}